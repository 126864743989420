<template>
    <div class="d-flex justify-content-center align-items-center">
        <ek-dialog 
            class="w-100"
            placeholder="ابحث عن فاتورة"
            @search="setSearch"
        ></ek-dialog>
        <b-button 
            class="w-25" 
            variant="primary" 
            @click="goToNewInvoice"
            > فاتورة جديدة
            <unicon 
                name="plus" 
                width="13" 
                height="13" 
                fill="#fff"
            />
        </b-button>
    </div>
</template>

<script>
export default {
    props:{
        id: String
    },
    methods: {
        goToNewInvoice() {
            this.$router.push({
                path: `/admin/invoice/invoiceTeacher/${this.id}/0`,
            });
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["serialNumber", "value"],
                query
            });
        }
    },
};
</script>
